.odslv-modal-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  
  &__left {
    font-family: 'NeuzeitGroReg', sans-serif;
    color: #444;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__right {
    cursor: pointer;
    img {
      height: 24px;
      opacity: 0.5;
    }
  }
}