.odslv-modal-h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .odslv-modal-h {
    margin-bottom: 0;
  }
}

.odslv-modal-h__left {
  font-family: 'NeuzeitGroReg', sans-serif;
  color: #444;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 16px;
}

.odslv-modal-h__right {
  cursor: pointer;
}

.odslv-modal-h__right img {
  height: 24px;
  opacity: 0.5;
}
