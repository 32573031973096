@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

$base-img-url: 'https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses';

$font-family-base: 'Founders Grotesk', sans-serif;
$font-fredpka: 'Fredoka', sans-serif;

$white: #fff;
$black: #000;
$yellow: #fcdb32;
$blue: #28b4b4;
$red: #e9534e;
$purple: #485ba7;
$sky-blue: #b3dee8;

@keyframes cloud {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50px);
  }
}

.fmc2l {
  font: 400 14px / calc(22 / 14) $font-family-base;
  letter-spacing: 0.42px;
  background-color: $sky-blue;
  color: $black;
  width: 100vw;
  overflow-x: hidden;
  picture,
  img {
    display: block;
    width: 100%;
  }
  section {
    position: relative;
    z-index: 1;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(1200px + 40px);
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 10rem;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: inherit;
    width: 100%;
    transition: all .3s ease;
    &--yellow {
      background: $yellow url('#{$base-img-url}/icon-caret-right--black.svg') no-repeat right 20px
        center/10px auto;
    }
    &--blue {
      color: $white;
      background: $blue url('#{$base-img-url}/icon-caret-right--white.svg') no-repeat right 20px
        center/10px auto;
    }
  }
  &__heading {
    font-family: $font-fredpka;
    font-weight: 700 !important;
    font-size: 23px;
    line-height: calc(32 / 23);
    letter-spacing: 1.15px;
    margin: 0;
    position: relative;
    z-index: 2;
    span {
      display: inline-block;
    }
    &-red {
      font: inherit;
      color: $red;
    }
    &-mark {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3px;
        height: 10px;
        background-color: $yellow;
        z-index: -1;
      }
    }
  }
  &__cloud {
    position: absolute;
    z-index: -1;
    &--1 {
      animation: cloud 5s linear both alternate infinite;
    }
    &--2 {
      animation: cloud 6s linear both alternate infinite;
    }
  }
  &__main {
    padding-top: 20px;
    margin-bottom: 100px;
    .fmc2l__cloud {
      &--1 {
        max-width: 215px;
        right: calc(50% + 50px);
        bottom: 110px;
      }
      &--2 {
        max-width: 181px;
        left: calc(50% + 70px);
        bottom: -40px;
      }
    }
    &-img {
      position: relative;
    }
    &-content {
      position: relative;
    }
    &-heading {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin: 0;
      img {
        max-width: 150px;
      }
      span {
        font: 700 28px / calc(36 / 28) $font-fredpka;
        letter-spacing: 0.84px;
        color: $purple;
      }
    }
    &-list {
      list-style: none;
      padding: 0;
      margin: 60px 0 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        display: flex;
        gap: 15px;
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
      }
    }
  }
  &__what {
    margin-bottom: 100px;
    .fmc2l__container {
      display: grid;
      gap: 40px;
    }
    &-video {
      border-radius: 0 100rem 100rem 0;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000, 0.3) url('#{$base-img-url}/icon-play.webp') no-repeat center/50px
          auto;
        transition: all 0.3s ease;
      }
      img {
        border-radius: inherit;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    &-description {
      margin: 0;
      sup {
        font-size: 10px;
      }
    }
  }
  &__uds {
    margin-bottom: 100px;
    .fmc2l__cloud {
      &--1 {
        top: -30px;
        right: calc(50% + 53px);
        max-width: 181px;
      }
      &--2 {
        left: calc(50% + 34px);
        bottom: -92px;
        max-width: 242px;
      }
    }
    &-grid {
      display: grid;
      gap: 20px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      p {
        margin: 0;
      }
    }
    &-card {
      background-color: rgba($white, 0.3);
      border-radius: 20px;
      padding: 40px 20px;
      &-title {
        font-size: 18px;
        font-weight: 500;
        line-height: calc(11 / 18);
        letter-spacing: 0.9;
        margin: 0 0 20px;
      }
      &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        li {
          display: inline-flex;
          align-items: center;
          gap: 20px 15px;
          img {
            object-fit: contain;
          }
          sup {
            font-size: 10px;
          }
        }
        &--features {
          li {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        &--benefits {
          li {
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    &-contents {
      list-style: none;
      padding: 0;
      margin: 40px 0 0;
      display: flex;
      flex-direction: column;
      gap: 40px;
      &-item {
        display: grid;
        gap: 20px;
      }
      &-details {
        display: flex;
        flex-direction: column;
        gap: 20px;
        h3 {
          font-size: 22px;
          font-weight: 500 !important;
          line-height: calc(32 / 22);
          letter-spacing: 1.1px;
          margin: 0;
          position: relative;
          z-index: 2;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -20px;
            width: 30px;
            height: 30px;
            background-color: $yellow;
            transform: translateY(-50%);
            z-index: -1;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
  &__about {
    margin-bottom: 100px;
    .fmc2l__cloud {
      &--1 {
        top: 224px;
        left: calc(50% + 421px);
        max-width: 479px;
      }
    }
    &-title {
      position: relative;
      z-index: 2;
      color: $white;
      margin: 0 0 20px;
      font: 700 23px / calc(32 / 23) $font-fredpka;
      font-weight: 700 !important;
      letter-spacing: 1.15px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -40px;
        right: -40px;
        bottom: 0;
        background-color: $red;
        z-index: -1;
      }
      span {
        margin: 0;
        display: inline-flex;
        padding: 15px 0;
        font-weight: inherit;
      }
    }
    &-text {
      margin: 0;
    }
    &-notes {
      margin: 20px 0 0;
      padding: 0;
      list-style: none;
      li {
        em {
          display: inline-flex;
          position: relative;
          cursor: pointer;
          font-style: normal;
          transition: opacity .3s ease;
          &::before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid $black;
          }
          &::after {
            content: '';
            width: 12px;
            height: 25px;
            margin-left: 0.25em;
            background: url('#{$base-img-url}/icon-external-link.svg') no-repeat center
              center/contain;
          }
          &:hover{
            opacity: 0.6;
          }
        }
      }
    }
  }
  &__cmmp {
    margin-bottom: 100px;
    .fmc2l {
      &__cloud {
        &--1 {
          max-width: 181px;
          top: -73px;
          left: calc(50% - 70px);
        }
      }
      &__heading {
        margin-bottom: 40px;
      }
    }
    &-text {
      margin: 0 0 38px;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        display: grid;
        grid-template-columns: 85px 1fr;
        gap: 10px;
        align-items: center;
        h3 {
          margin: 0;
          font-weight: 500 !important;
          font-size: 18px;
          line-height: normal;
          letter-spacing: 0.9px;
        }
        p {
          grid-column: span 2;
          margin: 0;
        }
      }
    }
  }
  &__rs {
    padding-bottom: 100px;

    .fmc2l__heading {
      margin-bottom: 10px;
    }
    &-card {
      padding: 60px 20px;
      background-color: $white;
      border-radius: 20px;
      margin-bottom: 18px;
    }
    &-grid {
      display: grid;
      gap: 20px;
    }
    &-logo {
      margin: 0;
      font: 0/0 a;
      img {
        max-width: 93px;
      }
    }
    &-text {
      motion: 0;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: grid;
      gap: 40px;
      li {
        display: flex;
        flex-direction: column;
        text-align: center;
        p {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 500;
          line-height: calc(11 / 18);
          letter-spacing: 0.9px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          span {
            display: inline-flex;
            width: 11px;
            height: 11px;
            border-radius: 10rem;
          }
        }
      }
    }
    &-actions {
      margin-top: 60px;
      display: grid;
      gap: 20px;
    }
    &-notes {
      margin: 0;
      padding: 0;
      list-style: none;
      counter-reset: counter;
      li {
        counter-increment: counter;
        position: relative;
        padding-left: 0.75em;
        &::before {
          content: counter(counter);
          font-size: 11px;
          position: absolute;
          top: 2px;
          left: 0;
        }
        a {
          display: inline-flex;
          width: 12px;
          height: 22px;
          margin-left: 0.25em;
          background: url('#{$base-img-url}/icon-external-link.svg') no-repeat center center/contain;
          transition: opacity .3s ease;
          &:hover{
            opacity: 0.6;
          }
        }
      }
    }
  }
  &__fixed-bottom {
    background-color: rgba($black, 0.5);
    box-shadow: 10px -10px 10px 0px rgba(194, 194, 194, 0.1) inset,
      -10px 10px 10px 0px rgba(255, 255, 255, 0.1) inset;
    backdrop-filter: blur(5px);
    padding: 20px 0;
    position: fixed !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    &-grid {
      display: grid;
      gap: 14px;
    }
    p {
      color: $white;
      text-align: center;
      font-size: 11px;
      letter-spacing: 0.55px;
      margin: 0;
      a {
        color: currentColor;
      }
    }
  }
  &__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($black, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding: 20px;
    }
    &-content {
      background: $white;
      color: $black;
      padding: 40px 20px;
      width: 100%;
      max-width: 900px;
      max-height: 90vh;
      position: relative;
      font-size: 14px;
      letter-spacing: 1px;
      overflow-y: auto;
    }
    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
    }
    h2 {
      text-align: center;
      font-weight: 500 !important;
      font-size: 20px;
      line-height: 1;
      letter-spacing: 0.02em;
      margin: 0 0 27px;
    }
    ul {
      list-style: none;
      padding-inline-start: 0;
      line-height: 2;
      margin-bottom: 0;
      li {
        position: relative;
        padding-left: 20px;
        &::before {
          content: '';
          position: absolute;
          top: 12px;
          left: 4px;
          width: 4px;
          height: 4px;
          background-color: $black;
          border-radius: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &__main {
      &-img {
        padding-left: 20px;
      }
      &-content {
        padding-left: 20px;
        padding-right: 20px;
      }
      &-heading {
        align-items: center;
        margin-top: -21px;
      }
    }
    &__what {
      &-video {
        margin-left: -20px;
      }
      &-content{
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &__uds {
      &-content {
        padding: 0 20px 18px;
      }
      &-contents {
        &-item {
          &:nth-child(odd) {
            .fmc2l__uds-contents-img {
              margin-left: -20px;
            }
          }
          &:nth-child(even) {
            .fmc2l__uds-contents-img {
              margin-right: -20px;
            }
          }
        }
        &-details {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    &__about {
      &-title {
        padding-left: 20px;
        padding-right: 20px;
      }
      &-img {
        margin-left: -20px;
        width: calc(100% + 20px) !important;
        margin-bottom: 20px;
        img {
          width: calc(100% + 20px);
        }
      }
      &-content {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &__cmmp {
      .fmc2l__heading,&-text,&-list{
        padding-left: 20px;
        padding-right: 20px;
      }
      &-list {
        margin-bottom: 40px;
      }
    }
    &__modal {
      &-content {
        padding-bottom: 200px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .md-none {
      display: none !important;
    }
    &__btn{
      &--yellow{
        &:hover{
          color: $white;
        background-color: $blue;
        background-image: url('#{$base-img-url}/icon-caret-right--white.svg');
        }
      }
      &--blue:hover{
        color: $white;
        background-color: $purple;
      }
    }
    &__main {
      &-img {
        margin-left: calc(50% - (50px / 2));
      }
      &-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
      }
      &-list {
        max-width: 355px;
      }
    }
    &__what {
      .fmc2l__container {
        grid-template-columns: 1fr 50%;
        align-items: center;
      }
      &-video {
        border-radius: 10px 222px 222px 10px;
        &:hover {
          &::before {
            background-color: rgba($black, 0);
            background-image: url('#{$base-img-url}/icon-play--hover.webp');
          }
        }
      }
      &-content {
        order: -1;
      }
    }
    &__uds {
      &-grid {
        grid-template-columns: repeat(2, 1fr);
      }
      &-content {
        grid-column: span 2;
      }
      &-contents {
        &-item {
          grid-template-columns: 1fr 50%;
          gap: 50px;
          align-items: center;
          &:nth-child(odd) {
            .fmc2l__uds-contents-img {
              img {
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
              }
            }
          }
          &:nth-child(even) {
            .fmc2l__uds-contents-img {
              img {
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
              }
            }
          }
        }
        &-details {
          h3 {
            &::before {
              top: 2px;
              left: 0;
              transform: translate(-50%, 0);
            }
          }
        }
      }
    }
    &__about {
      .fmc2l__container {
        display: grid;
        grid-template-areas: 'title img' 'content img';
        grid-template-columns: 1fr 50%;
        gap: 0 50px;
      }
      &-title {
        grid-area: title;
        display: inline-flex;
        &::before {
          left: -1000%;
          right: 10px;
          border-top-right-radius: 10rem;
          border-bottom-right-radius: 10rem;
        }
      }
      &-img {
        grid-area: img;
        img {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
      }
      &-content {
        grid-area: content;
      }
    }
    &__cmmp {
      .fmc2l {
        &__container {
          display: grid;
          grid-template-areas: 'heading list' 'text list' 'button list';
          grid-template-columns: 1fr 50%;
          grid-template-rows: auto auto 1fr;
          gap: 0 50px;
          align-items: flex-start;
        }
        &__heading {
          grid-area: heading;
        }
        &__btn {
          grid-area: button;
          max-width: 400px;
          margin-top: 50px;
        }
      }
      &-text {
        grid-area: text;
        margin-bottom: 0;
      }
      &-list {
        grid-area: list;
        gap: 50px;
        li {
          grid-template-columns: 113px 1fr;
          grid-template-rows: auto 1fr;
          align-items: flex-start;
          gap: 10px 20px;
          img {
            grid-row: span 2;
          }
          p {
            grid-column-start: 2;
          }
        }
      }
    }
    &__rs {
      .fmc2l__heading {
        grid-area: heading;
        margin-bottom: 50px;
      }
      &-grid {
        grid-template-areas: 'heading img' 'logo img' 'text img';
        grid-template-columns: 1fr 50%;
        grid-template-rows: auto auto 1fr;
        gap: 0 60px;
        align-items: flex-start;
      }
      &-logo {
        grid-area: logo;
        margin-bottom: 30px;
      }
      &-img {
        grid-area: img;
      }
      &-text {
        grid-area: text;
        margin-bottom: 0;
      }
      &-list {
        grid-template-columns: repeat(3, 1fr);
      }
      &-actions {
        grid-template-columns: repeat(2, 1fr);
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__fixed-bottom {
      .fmc2l {
        &__container {
          position: relative;
        }
        &__btn {
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      p {
        position: absolute;
        top: 50%;
        left: 6%;
        transform: translateY(-50%);
      }
    }
  }
  @media screen and (min-width: 1024px) {
    font-size: 16px;
    letter-spacing: 0.48px;
    &__btn {
      height: 60px;
      font-size: 20px;
      letter-spacing: 1px;
    }
    &__heading {
      font-size: 30px;
      letter-spacing: 1.5px;
      &-mark {
        &::before {
          height: 14px;
        }
      }
    }
    &__main {
      padding: 48px 0;
      .fmc2l__cloud {
        &--1 {
          max-width: 400px;
          top: 120px;
          left: auto;
          right: calc(50% + 392px);
        }
        &--2 {
          max-width: 312px;
          bottom: 45px;
          left: auto;
          right: calc(50% + 18px);
        }
      }
      &-img {
        margin-left: calc(50% - 50px);
      }
      &-heading {
        img {
          max-width: 206px;
          margin-top: 8px;
        }
        span {
          font-size: 40px;
          letter-spacing: 1.2px;
        }
      }
      &-list {
        font-size: 20px;
        letter-spacing: 1px;
        sup {
          font-size: 12px;
        }
      }
    }
    &__what {
      margin: 150px 0;
      .fmc2l__container {
        gap: 50px;
      }
      &-video {
        &::before {
          background-size: 70px auto;
        }
      }
      &-content {
        gap: 50px;
      }
    }
    &__uds {
      margin-bottom: 150px;
      .fmc2l__cloud {
        &--1 {
          max-width: 400px;
          top: 4px;
          left: auto;
          right: calc(50% + 369px);
        }
        &--2 {
          max-width: 312px;
          bottom: 240px;
          left: calc(50% - 83px);
        }
      }
      &-grid {
        grid-template-columns: 2fr 2fr 3fr;
        gap: 30px;
      }
      &-content {
        grid-column: span 1;
      }
      &-card {
        padding: 50px;
        &-title {
          font-size: 20px;
        }
      }
      &-contents {
        margin-top: 75px;
        gap: 75px;
        &-item {
          gap: 100px;
        }
      }
    }
    &__about {
      margin-bottom: 150px;
      &-title {
        font-size: 30px;
        line-height: 22px;
        margin-bottom: 50px;
      }
      &-notes {
        margin-top: 50px;
      }
    }
    &__cmmp {
      margin-bottom: 150px;
      .fmc2l {
        &__cloud {
          &--1 {
            max-width: 312px;
            top: -98px;
            left: auto;
            right: calc(50% + 475px);
          }
        }
        &__heading {
          margin-bottom: 50px;
        }
        // &__btn {
        //   margin-top: 50px;
        // }
      }
    }
    &__rs {
      padding-bottom: 150px;
      &-card {
        padding: 100px 60px;
        margin-bottom: 50px;
      }
      &-logo {
        img {
          max-width: 149px;
        }
      }
      &-list {
        gap: 90px;
        margin: 50px 0 100px;
      }
      &-actions {
        gap: 100px;
      }
    }
    &__modal {
      &-close {
        width: 38px;
        height: 38px;
        top: 35px;
        right: 35px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-content {
        padding: 60px 100px;
        font-size: 15px;
        border-radius: 10px;
      }
      h2 {
        font-size: 22px;
        margin-bottom: 60px;
      }
    }
  }
}
