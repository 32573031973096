@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap");
@-webkit-keyframes cloud {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
}
@keyframes cloud {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
}
.fmc2l {
  font: 400 14px/1.57143 "Founders Grotesk", sans-serif;
  letter-spacing: 0.42px;
  background-color: #b3dee8;
  color: #000;
  width: 100vw;
  overflow-x: hidden;
}
.fmc2l picture,
.fmc2l img {
  display: block;
  width: 100%;
}
.fmc2l section {
  position: relative;
  z-index: 1;
}
.fmc2l a {
  text-decoration: none;
}
.fmc2l a:hover {
  text-decoration: none;
}
.fmc2l__container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1240px;
}
.fmc2l__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
  border-radius: 10rem;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: inherit;
  width: 100%;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.fmc2l__btn--yellow {
  background: #fcdb32 url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-caret-right--black.svg") no-repeat right 20px center/10px auto;
}
.fmc2l__btn--blue {
  color: #fff;
  background: #28b4b4 url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-caret-right--white.svg") no-repeat right 20px center/10px auto;
}
.fmc2l__heading {
  font-family: "Fredoka", sans-serif;
  font-weight: 700 !important;
  font-size: 23px;
  line-height: 1.3913;
  letter-spacing: 1.15px;
  margin: 0;
  position: relative;
  z-index: 2;
}
.fmc2l__heading span {
  display: inline-block;
}
.fmc2l__heading-red {
  font: inherit;
  color: #e9534e;
}
.fmc2l__heading-mark {
  position: relative;
}
.fmc2l__heading-mark::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3px;
  height: 10px;
  background-color: #fcdb32;
  z-index: -1;
}
.fmc2l__cloud {
  position: absolute;
  z-index: -1;
}
.fmc2l__cloud--1 {
  -webkit-animation: cloud 5s linear both alternate infinite;
          animation: cloud 5s linear both alternate infinite;
}
.fmc2l__cloud--2 {
  -webkit-animation: cloud 6s linear both alternate infinite;
          animation: cloud 6s linear both alternate infinite;
}
.fmc2l__main {
  padding-top: 20px;
  margin-bottom: 100px;
}
.fmc2l__main .fmc2l__cloud--1 {
  max-width: 215px;
  right: calc(50% + 50px);
  bottom: 110px;
}
.fmc2l__main .fmc2l__cloud--2 {
  max-width: 181px;
  left: calc(50% + 70px);
  bottom: -40px;
}
.fmc2l__main-img {
  position: relative;
}
.fmc2l__main-content {
  position: relative;
}
.fmc2l__main-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px;
  margin: 0;
}
.fmc2l__main-heading img {
  max-width: 150px;
}
.fmc2l__main-heading span {
  font: 700 28px/1.28571 "Fredoka", sans-serif;
  letter-spacing: 0.84px;
  color: #485ba7;
}
.fmc2l__main-list {
  list-style: none;
  padding: 0;
  margin: 60px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}
.fmc2l__main-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
.fmc2l__main-list li img {
  width: 40px;
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
}
.fmc2l__what {
  margin-bottom: 100px;
}
.fmc2l__what .fmc2l__container {
  display: grid;
  gap: 40px;
}
.fmc2l__what-video {
  border-radius: 0 100rem 100rem 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.fmc2l__what-video::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3) url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-play.webp") no-repeat center/50px auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fmc2l__what-video img {
  border-radius: inherit;
}
.fmc2l__what-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
}
.fmc2l__what-description {
  margin: 0;
}
.fmc2l__what-description sup {
  font-size: 10px;
}
.fmc2l__uds {
  margin-bottom: 100px;
}
.fmc2l__uds .fmc2l__cloud--1 {
  top: -30px;
  right: calc(50% + 53px);
  max-width: 181px;
}
.fmc2l__uds .fmc2l__cloud--2 {
  left: calc(50% + 34px);
  bottom: -92px;
  max-width: 242px;
}
.fmc2l__uds-grid {
  display: grid;
  gap: 20px;
}
.fmc2l__uds-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
}
.fmc2l__uds-content p {
  margin: 0;
}
.fmc2l__uds-card {
  background-color: rgba(255, 255, 255, .3);
  border-radius: 20px;
  padding: 40px 20px;
}
.fmc2l__uds-card-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 0.61111;
  letter-spacing: 0.9;
  margin: 0 0 20px;
}
.fmc2l__uds-card-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}
.fmc2l__uds-card-list li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px 15px;
}
.fmc2l__uds-card-list li img {
  -o-object-fit: contain;
     object-fit: contain;
}
.fmc2l__uds-card-list li sup {
  font-size: 10px;
}
.fmc2l__uds-card-list--features li img {
  width: 20px;
  height: 20px;
}
.fmc2l__uds-card-list--benefits li img {
  width: 40px;
  height: 40px;
}
.fmc2l__uds-contents {
  list-style: none;
  padding: 0;
  margin: 40px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
}
.fmc2l__uds-contents-item {
  display: grid;
  gap: 20px;
}
.fmc2l__uds-contents-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}
.fmc2l__uds-contents-details h3 {
  font-size: 22px;
  font-weight: 500 !important;
  line-height: 1.45455;
  letter-spacing: 1.1px;
  margin: 0;
  position: relative;
  z-index: 2;
}
.fmc2l__uds-contents-details h3::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 30px;
  height: 30px;
  background-color: #fcdb32;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}
.fmc2l__uds-contents-details p {
  margin: 0;
}
.fmc2l__about {
  margin-bottom: 100px;
}
.fmc2l__about .fmc2l__cloud--1 {
  top: 224px;
  left: calc(50% + 421px);
  max-width: 479px;
}
.fmc2l__about-title {
  position: relative;
  z-index: 2;
  color: #fff;
  margin: 0 0 20px;
  font: 700 23px/1.3913 "Fredoka", sans-serif;
  font-weight: 700 !important;
  letter-spacing: 1.15px;
}
.fmc2l__about-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: -40px;
  right: -40px;
  bottom: 0;
  background-color: #e9534e;
  z-index: -1;
}
.fmc2l__about-title span {
  margin: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 15px 0;
  font-weight: inherit;
}
.fmc2l__about-text {
  margin: 0;
}
.fmc2l__about-notes {
  margin: 20px 0 0;
  padding: 0;
  list-style: none;
}
.fmc2l__about-notes li em {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  font-style: normal;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
.fmc2l__about-notes li em::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #000;
}
.fmc2l__about-notes li em::after {
  content: '';
  width: 12px;
  height: 25px;
  margin-left: 0.25em;
  background: url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-external-link.svg") no-repeat center center/contain;
}
.fmc2l__about-notes li em:hover {
  opacity: 0.6;
}
.fmc2l__cmmp {
  margin-bottom: 100px;
}
.fmc2l__cmmp .fmc2l__cloud--1 {
  max-width: 181px;
  top: -73px;
  left: calc(50% - 70px);
}
.fmc2l__cmmp .fmc2l__heading {
  margin-bottom: 40px;
}
.fmc2l__cmmp-text {
  margin: 0 0 38px;
}
.fmc2l__cmmp-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}
.fmc2l__cmmp-list li {
  display: grid;
  grid-template-columns: 85px 1fr;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.fmc2l__cmmp-list li h3 {
  margin: 0;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.9px;
}
.fmc2l__cmmp-list li p {
  grid-column: span 2;
  margin: 0;
}
.fmc2l__rs {
  padding-bottom: 100px;
}
.fmc2l__rs .fmc2l__heading {
  margin-bottom: 10px;
}
.fmc2l__rs-card {
  padding: 60px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 18px;
}
.fmc2l__rs-grid {
  display: grid;
  gap: 20px;
}
.fmc2l__rs-logo {
  margin: 0;
  font: 0/0 a;
}
.fmc2l__rs-logo img {
  max-width: 93px;
}
.fmc2l__rs-text {
  motion: 0;
}
.fmc2l__rs-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 40px;
}
.fmc2l__rs-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
.fmc2l__rs-list li p {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 0.61111;
  letter-spacing: 0.9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.fmc2l__rs-list li p span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 11px;
  height: 11px;
  border-radius: 10rem;
}
.fmc2l__rs-actions {
  margin-top: 60px;
  display: grid;
  gap: 20px;
}
.fmc2l__rs-notes {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: counter;
}
.fmc2l__rs-notes li {
  counter-increment: counter;
  position: relative;
  padding-left: 0.75em;
}
.fmc2l__rs-notes li::before {
  content: counter(counter);
  font-size: 11px;
  position: absolute;
  top: 2px;
  left: 0;
}
.fmc2l__rs-notes li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 12px;
  height: 22px;
  margin-left: 0.25em;
  background: url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-external-link.svg") no-repeat center center/contain;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
.fmc2l__rs-notes li a:hover {
  opacity: 0.6;
}
.fmc2l__fixed-bottom {
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 10px -10px 10px 0px rgba(194, 194, 194, .1) inset, -10px 10px 10px 0px rgba(255, 255, 255, .1) inset;
          box-shadow: 10px -10px 10px 0px rgba(194, 194, 194, .1) inset, -10px 10px 10px 0px rgba(255, 255, 255, .1) inset;
  backdrop-filter: blur(5px);
  padding: 20px 0;
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.fmc2l__fixed-bottom-grid {
  display: grid;
  gap: 14px;
}
.fmc2l__fixed-bottom p {
  color: #fff;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.55px;
  margin: 0;
}
.fmc2l__fixed-bottom p a {
  color: currentColor;
}
.fmc2l__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1000;
  padding: 20px;
}
.fmc2l__modal-content {
  background: #fff;
  color: #000;
  padding: 40px 20px;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  overflow-y: auto;
}
.fmc2l__modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.fmc2l__modal h2 {
  text-align: center;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.02em;
  margin: 0 0 27px;
}
.fmc2l__modal ul {
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  line-height: 2;
  margin-bottom: 0;
}
.fmc2l__modal ul li {
  position: relative;
  padding-left: 20px;
}
.fmc2l__modal ul li::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 4px;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 100%;
}
@media screen and (max-width: 767px) {
  .fmc2l__main-img {
    padding-left: 20px;
  }
  .fmc2l__main-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fmc2l__main-heading {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: -21px;
  }
  .fmc2l__what-video {
    margin-left: -20px;
  }
  .fmc2l__what-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fmc2l__uds-content {
    padding: 0 20px 18px;
  }
  .fmc2l__uds-contents-item:nth-child(odd) .fmc2l__uds-contents-img {
    margin-left: -20px;
  }
  .fmc2l__uds-contents-item:nth-child(even) .fmc2l__uds-contents-img {
    margin-right: -20px;
  }
  .fmc2l__uds-contents-details {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fmc2l__about-title {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fmc2l__about-img {
    margin-left: -20px;
    width: calc(100% + 20px) !important;
    margin-bottom: 20px;
  }
  .fmc2l__about-img img {
    width: calc(100% + 20px);
  }
  .fmc2l__about-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fmc2l__cmmp .fmc2l__heading, .fmc2l__cmmp-text, .fmc2l__cmmp-list {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fmc2l__cmmp-list {
    margin-bottom: 40px;
  }
  .fmc2l__modal-content {
    padding-bottom: 200px;
  }
}
@media screen and (min-width: 768px) {
  .fmc2l .md-none {
    display: none !important;
  }
  .fmc2l__btn--yellow:hover {
    color: #fff;
    background-color: #28b4b4;
    background-image: url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-caret-right--white.svg");
  }
  .fmc2l__btn--blue:hover {
    color: #fff;
    background-color: #485ba7;
  }
  .fmc2l__main-img {
    margin-left: calc(50% - 25px);
  }
  .fmc2l__main-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .fmc2l__main-list {
    max-width: 355px;
  }
  .fmc2l__what .fmc2l__container {
    grid-template-columns: 1fr 50%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .fmc2l__what-video {
    border-radius: 10px 222px 222px 10px;
  }
  .fmc2l__what-video:hover::before {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses/icon-play--hover.webp");
  }
  .fmc2l__what-content {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .fmc2l__uds-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .fmc2l__uds-content {
    grid-column: span 2;
  }
  .fmc2l__uds-contents-item {
    grid-template-columns: 1fr 50%;
    gap: 50px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .fmc2l__uds-contents-item:nth-child(odd) .fmc2l__uds-contents-img img {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  .fmc2l__uds-contents-item:nth-child(even) .fmc2l__uds-contents-img img {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .fmc2l__uds-contents-details h3::before {
    top: 2px;
    left: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  .fmc2l__about .fmc2l__container {
    display: grid;
    grid-template-areas: 'title img' 'content img';
    grid-template-columns: 1fr 50%;
    gap: 0 50px;
  }
  .fmc2l__about-title {
    grid-area: title;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .fmc2l__about-title::before {
    left: -1000%;
    right: 10px;
    border-top-right-radius: 160px;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 160px;
    border-bottom-right-radius: 10rem;
  }
  .fmc2l__about-img {
    grid-area: img;
  }
  .fmc2l__about-img img {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  .fmc2l__about-content {
    grid-area: content;
  }
  .fmc2l__cmmp .fmc2l__container {
    display: grid;
    grid-template-areas: 'heading list' 'text list' 'button list';
    grid-template-columns: 1fr 50%;
    grid-template-rows: auto auto 1fr;
    gap: 0 50px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .fmc2l__cmmp .fmc2l__heading {
    grid-area: heading;
  }
  .fmc2l__cmmp .fmc2l__btn {
    grid-area: button;
    max-width: 400px;
    margin-top: 50px;
  }
  .fmc2l__cmmp-text {
    grid-area: text;
    margin-bottom: 0;
  }
  .fmc2l__cmmp-list {
    grid-area: list;
    gap: 50px;
  }
  .fmc2l__cmmp-list li {
    grid-template-columns: 113px 1fr;
    grid-template-rows: auto 1fr;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 10px 20px;
  }
  .fmc2l__cmmp-list li img {
    grid-row: span 2;
  }
  .fmc2l__cmmp-list li p {
    grid-column-start: 2;
  }
  .fmc2l__rs .fmc2l__heading {
    grid-area: heading;
    margin-bottom: 50px;
  }
  .fmc2l__rs-grid {
    grid-template-areas: 'heading img' 'logo img' 'text img';
    grid-template-columns: 1fr 50%;
    grid-template-rows: auto auto 1fr;
    gap: 0 60px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .fmc2l__rs-logo {
    grid-area: logo;
    margin-bottom: 30px;
  }
  .fmc2l__rs-img {
    grid-area: img;
  }
  .fmc2l__rs-text {
    grid-area: text;
    margin-bottom: 0;
  }
  .fmc2l__rs-list {
    grid-template-columns: repeat(3, 1fr);
  }
  .fmc2l__rs-actions {
    grid-template-columns: repeat(2, 1fr);
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  .fmc2l__fixed-bottom .fmc2l__container {
    position: relative;
  }
  .fmc2l__fixed-bottom .fmc2l__btn {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .fmc2l__fixed-bottom p {
    position: absolute;
    top: 50%;
    left: 6%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@media screen and (min-width: 1024px) {
  .fmc2l {
    font-size: 16px;
    letter-spacing: 0.48px;
  }
  .fmc2l__btn {
    height: 60px;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .fmc2l__heading {
    font-size: 30px;
    letter-spacing: 1.5px;
  }
  .fmc2l__heading-mark::before {
    height: 14px;
  }
  .fmc2l__main {
    padding: 48px 0;
  }
  .fmc2l__main .fmc2l__cloud--1 {
    max-width: 400px;
    top: 120px;
    left: auto;
    right: calc(50% + 392px);
  }
  .fmc2l__main .fmc2l__cloud--2 {
    max-width: 312px;
    bottom: 45px;
    left: auto;
    right: calc(50% + 18px);
  }
  .fmc2l__main-img {
    margin-left: calc(50% - 50px);
  }
  .fmc2l__main-heading img {
    max-width: 206px;
    margin-top: 8px;
  }
  .fmc2l__main-heading span {
    font-size: 40px;
    letter-spacing: 1.2px;
  }
  .fmc2l__main-list {
    font-size: 20px;
    letter-spacing: 1px;
  }
  .fmc2l__main-list sup {
    font-size: 12px;
  }
  .fmc2l__what {
    margin: 150px 0;
  }
  .fmc2l__what .fmc2l__container {
    gap: 50px;
  }
  .fmc2l__what-video::before {
    background-size: 70px auto;
  }
  .fmc2l__what-content {
    gap: 50px;
  }
  .fmc2l__uds {
    margin-bottom: 150px;
  }
  .fmc2l__uds .fmc2l__cloud--1 {
    max-width: 400px;
    top: 4px;
    left: auto;
    right: calc(50% + 369px);
  }
  .fmc2l__uds .fmc2l__cloud--2 {
    max-width: 312px;
    bottom: 240px;
    left: calc(50% - 83px);
  }
  .fmc2l__uds-grid {
    grid-template-columns: 2fr 2fr 3fr;
    gap: 30px;
  }
  .fmc2l__uds-content {
    grid-column: span 1;
  }
  .fmc2l__uds-card {
    padding: 50px;
  }
  .fmc2l__uds-card-title {
    font-size: 20px;
  }
  .fmc2l__uds-contents {
    margin-top: 75px;
    gap: 75px;
  }
  .fmc2l__uds-contents-item {
    gap: 100px;
  }
  .fmc2l__about {
    margin-bottom: 150px;
  }
  .fmc2l__about-title {
    font-size: 30px;
    line-height: 22px;
    margin-bottom: 50px;
  }
  .fmc2l__about-notes {
    margin-top: 50px;
  }
  .fmc2l__cmmp {
    margin-bottom: 150px;
  }
  .fmc2l__cmmp .fmc2l__cloud--1 {
    max-width: 312px;
    top: -98px;
    left: auto;
    right: calc(50% + 475px);
  }
  .fmc2l__cmmp .fmc2l__heading {
    margin-bottom: 50px;
  }
  .fmc2l__rs {
    padding-bottom: 150px;
  }
  .fmc2l__rs-card {
    padding: 100px 60px;
    margin-bottom: 50px;
  }
  .fmc2l__rs-logo img {
    max-width: 149px;
  }
  .fmc2l__rs-list {
    gap: 90px;
    margin: 50px 0 100px;
  }
  .fmc2l__rs-actions {
    gap: 100px;
  }
  .fmc2l__modal-close {
    width: 38px;
    height: 38px;
    top: 35px;
    right: 35px;
  }
  .fmc2l__modal-close img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .fmc2l__modal-content {
    padding: 60px 100px;
    font-size: 15px;
    border-radius: 10px;
  }
  .fmc2l__modal h2 {
    font-size: 22px;
    margin-bottom: 60px;
  }
}
